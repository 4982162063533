import { BaseService } from '@/api/request';
const api = {};
// NaN undefined null过滤处理
// 注意，尽量不要过滤空字符串
const filterNull = json => {
  let newJson = {};
  let reg = RegExp(/NaN|undefined|null/);
  for (let li in json) {
    if (json[li] && !reg.test(json[li])) {
      newJson[li] = json[li];
    }
  }
  return newJson;
};

/**
 * @description: 获取项目报表汇总的数据
 */
api.projSummaryData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/project/report/summary', filterNull(params));
};
api.projStatisticData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/project/report/serving', filterNull(params));
};
api.projPMData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/project/report/pm', filterNull(params));
};
api.projStaffData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/project/report/staff', filterNull(params));
};
api.getSearchUserName = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/user/getUser', filterNull(params));
};
api.getDesignativeUserName = function (params = {}) {
  return BaseService.getRequest('/api/pmf/v1/users/leaders', filterNull(params));
};
api.getDepartment = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/groups', params);
};
api.projIdSummaryData = function (params = {}, showMsg = false) {
  return BaseService.getRequest('api/pmf/v1/project/report/summary/projects', filterNull(params), { showMsg });
};
api.projIdStatisticData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/project/report/serving/projects', filterNull(params));
};
api.projIdPMData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/project/report/pm/projects', filterNull(params));
};
api.projReportData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/project/report/project', filterNull(params));
};
// 项目列表
api.projectList = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/projects', filterNull(params));
};
api.addProject = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/projects', filterNull(params));
};
api.editProject = function (params = {}, projId) {
  return BaseService.putRequest('api/pmf/v1/projects/' + projId, filterNull(params));
};
// 通用导出，自带参数指定
api.projExport = function (params = {}, exportName = '') {
  return BaseService.getRequest('/api/pmf/v1/project/report/' + exportName + '/export', filterNull(params), {
    otherRequestConfig: { responseType: 'blob', timeout: 1000 * 60 },
  });
};
api.projMoreExport = function (params = {}) {
  return BaseService.getRequest('/api/pmf/v1/project/report/staff/moreExport', filterNull(params), {
    otherRequestConfig: { responseType: 'blob', timeout: 1000 * 60 },
  });
};

export default api;
