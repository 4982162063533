/*
 *@Author: 胡广源
 *@Date: 2022-04-08 17:06:25
 *@Description:表格的合并计算函数(groupByFrom)
 */
//函数groupByFrom的携带参数：data为表格数据,params为对齐字段，以该字段为合并标准字段
export default function groupByFrom(data, params) {
  const groups = {};
  data.forEach(item => {
    // 根据模拟数据 是通过字段来分组,获取data中的传入的params属性对应的属性值 ，放入数组中：["2222"]，再将属性值转换为json字符串：'["2222"]'
    const group = JSON.stringify(item[params]);
    // 判断group在对象中是否存在，若无则将group作为groups的key，value返回一个空数组初始化。若已存在则不变
    groups[group] = groups[group] || [];
    // 将对应找到的值作为value放入数组中
    groups[group].push(item);
  });
  // 返回处理好的二维数组 （如果想返回groupBy形式的数据只返回groups即可）
  return Object.values(groups);
}
