<template>
  <div class="projectTable">
    <slot name="headerDescription"></slot>
    <el-table
      id="table"
      style="width: 100%"
      :data="formData"
      :max-height="tableHeight"
      :summary-method="getSummaries"
      :span-method="objectSpanMethod"
      show-summary
      :cell-class-name="cellMeshStyle"
      ref="table"
      empty-text=" "
      :class="{ show_empty: showEmpty && !tableLoading }"
      :row-class-name="tableRowClassName"
      border
      v-loadmore="loadMore"
      v-loading="tableLoading"
    >
      <el-table-column
        v-for="(item, index) in columns"
        :key="index + 50"
        :prop="item.prop"
        :label="item.label"
        :align="item.align"
        :min-width="setColumnWidth(item.label) || 120"
      >
        <template slot-scope="scope">
          <el-popover
            trigger="hover"
            placement="top"
            :content="scope.row.sponsorFullName ? scope.row.sponsorFullName : scope.row.sponsorName"
            v-if="item.prop === 'sponsorName'"
          >
            <span slot="reference" class="column-pointer">{{ scope.row[item.prop] }} </span>
          </el-popover>
          <span v-else-if="scope.column.property === locationRoute" class="toLocation">{{ scope.row[item.prop] }} </span>
          <span v-else>{{ scope.row[item.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <slot name="bottomDescription"></slot>
  </div>
</template>

<script>
import groupByFrom from '@/utils/groupByFrom';
export default {
  name: 'TablePane',
  props: {
    locationRoute: {
      type: String,
      default: '',
    },
    sumColumn: {
      type: Number,
      default: 0,
    },
    splitColumn: {
      type: Number,
      default: 0,
    },

    // 前端计算总计/后端直接返回总计的判断,false为前端计算
    totalJudgment: {
      type: Boolean,
      default: false,
    },
    // 合并的参考维度判断字段
    keyParams: String,
    currentPage: Number,
    totalPage: Number,
    tableLoading: Boolean,
    countSummary: Array,
    submitTip: {
      type: Boolean,
      default: false,
    },
    tableHeight: {
      type: Number,
      default: 0,
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      spanArr: [],
      formData: [],
      dataSum: [],
      valueSums: [],
      sumColumnName: undefined,
      showEmpty: false,
      realHeight: 0,
    };
  },
  created() {
    // 获取合计行的对应列表头名
    this.sumColumnName = this.columns[this.sumColumn].prop;
  },
  activated() {
    if (this.$refs.table.$el.offsetHeight < this.tableHeight && this.keyParams) {
      this.loadMore();
    }
    this.$refs.table.doLayout();
  },
  updated() {
    this.$refs.table.doLayout();
  },
  watch: {
    // 后台数据加载需要较长的等待时间，故需要对数据进行一个检测，有数据时才执行
    tableData: function (newVal) {
      if (newVal && newVal.length > 0) {
        if (this.$refs.table.$el.offsetHeight && this.$refs.table.$el.offsetHeight < this.tableHeight && this.keyParams) {
          this.loadMore();
        }
        if (this.keyParams) {
          this.dataSum = [];
          let filterArray = [];
          // 在这里遍历并且汇总需要合计的同一项目的数据，然后生成新的合计行数组插入表单数据，在封装的table组件里面进行重排处理
          newVal.forEach(res => {
            if (!filterArray.includes(res.projectName)) {
              filterArray.push(res.projectName);
              let sumRow = {
                sponsorName: '',
                projectName: res.projectName,
                projectBeginDate: '',
                boName: '',
                pmName: '',
                realBoName: '',
                realPmName: '',
                servingName: '',
                username: '',
                duration: res.duration,
                totalDuration: res.totalDuration,
              };
              // 进行分离式添加属性是为了将合计字符串插入每一行的合计列之中
              sumRow[this.sumColumnName] = '合计';
              // 收集合计行
              this.dataSum.push(sumRow);
            } else {
              this.dataSum.forEach(k => {
                if (k.projectName === res.projectName) {
                  k.duration += res.duration;
                  k.totalDuration += res.totalDuration;
                }
              });
            }
          });
          const filterData = newVal.concat(this.dataSum);
          this.getSpanArr(filterData, this.keyParams);
        } else {
          this.formData = this.formData.concat(newVal);
        }
        this.showEmpty = false;
      } else {
        // 每次发起提交请求前，都会先清空一次formData,避免重复添加
        this.formData = [];
        this.showEmpty = true;
      }
    },
  },
  // 注销window.onresize事件
  /*   beforeRouteLeave(to, from, next) {
    //离开组件的时候触发
    window.onresize = null;
    next();
  }, */
  methods: {
    loadMore() {
      if (this.submitTip) {
        return;
      }
      if (this.currentPage < this.totalPage) {
        if (this.tableLoading) {
          return;
        }

        this.keyParams ? this.$emit('update:currentPage', this.currentPage + 5) : this.$emit('update:currentPage', this.currentPage + 1);
        // 携带参数布尔值是专门为直接从数据库获取表格数据的请求而设定的，用于判定是否是下拉加载
        this.$emit('refreshData');
      }
    },
    setColumnWidth(label) {
      switch (label) {
        case 'PM':
        case 'BO':
        case '总监':
        case '员工姓名':
          return '100px';
        case 'Senior Manager':
          return '130px';
        case '项目开始时间':
          return '150px';
        case '服务项':
          return '260px';
        case '项目名称':
          return '230px';
        case '申办方':
        case '申办方编号':
          return '160px';
        case '阶段工时':
        case '累计工时':
        case '月度工时':
          return '90px';
        case 'Manager':
          return '90px';
      }
    },
    // 计算汇总逻辑
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [];
      let v = 0;
      // 每次处理以列为单元
      columns.forEach((column, index) => {
        // sumColumn为选定的合计单元格所在列的位置，需要自定义
        if (index === this.sumColumn) {
          sums[index] = '总计';
          return;
        }
        if (index < this.sumColumn) {
          return;
        }
        if (this.totalJudgment && data.length > 0) {
          sums[index] = this.countSummary[v++];
          return;
        }
        // 变更设计，直接用合计行的数据进行计算，取消逐行逐行对比计算，优化性能
        const values = data
          .map(item => {
            if (item[this.sumColumnName] === '合计') {
              return Number(item[column.property]);
            }
          })
          .filter(Boolean);
        // 遍历列数据后，筛选均满足Number隐性转换后的处理结果，通过则将values内部的值进行累加
        if (!values.every(value => Number.isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);

            if (!Number.isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    // 构造一个SpanArr数组赋予rowspan，完成控制行合并
    getSpanArr(data, params) {
      // 接收重构数组
      let arr = [];

      // 设置数组的索引
      let pos = 0;

      // 控制合并的数组
      this.spanArr = [];
      // arr 处理，将获取到的基于keyparams关键字重排的数组进行遍历，然后将其扁平化至一个新数组arr
      // 在这个过程中，提前将需要合计的行以手动的方式添加进data中，利用同名的特性将其归类至同一队列
      groupByFrom(data, params).map(v => {
        arr = arr.concat(v);
      });
      arr.forEach(res => {
        if (res[this.sumColumnName] === '合计') {
          res.projectName = ' ';
        }
      });
      // 在完成对数据的重排之后，更新tableData的数据
      this.formData = this.formData.concat(arr);

      // 下面是基于双循环算法思路扩展的重复计数+数组索引的模式设计，为了构造一个给rowspan曲直判断合并的数组
      // 数组中大于0的数字就是我们要合并的这组数据的数量，对齐这组数据需要合并的列数，0代表这组数据不进行合并
      const redata = this.formData.map(v => {
        return v[params];
      });

      // i是索引
      redata.reduce((old, cur, i) => {
        // 第一次判断先增加一个1占位，索引为0
        if (i === 0) {
          this.spanArr.push(1);
          pos = 0;
        } else {
          // 若前后两个keyParam相等，则将对应索引的keyparam的值+1，并自动在后面增加一个0占位
          if (cur === old) {
            this.spanArr[pos] += 1;
            this.spanArr.push(0);
          }
          // 否则增加一个1占位，记录当前索引的位置
          else {
            this.spanArr.push(1);
            pos = i;
          }
        }
        return cur;
      }, {});
    },
    tableRowClassName({ row }) {
      if (this.keyParams) {
        this.$emit('update:submitTip', false);
      }

      if (row[this.sumColumnName] === '合计') {
        return 'summary-row';
      }
    },
    // 合并 需要进行合并的表格行和列，splitColumn指定从哪一列开始合并
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex < this.splitColumn) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    cellMeshStyle(column) {
      /*       if (column.columnIndex >= this.sumColumn && column.row[this.sumColumnName] === '合计') {
        return 'uncombine-tableCell';
      } */
      if (column.columnIndex < this.splitColumn && column.row[this.sumColumnName] !== '合计') {
        return 'combine-tableCell';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
::v-deep .el-table__row {
  .el-table__cell {
    padding-top: 4.5px;
    padding-bottom: 4.5px;
  }
}
.projectTable {
  padding: 16px 32px;
  background: #fff;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 6px;
  overflow: hidden;
  border-radius: 2px;
  .column-pointer {
    cursor: default;
  }
  .toLocation {
    cursor: pointer;
    color: #2d8cf0;
  }
  @import url('@styles/element-ui.scss');
  @include tableClass;
}
.show_empty {
  height: 610px;
  max-height: 631px !important;
}
</style>
