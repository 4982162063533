<template>
  <div class="editServiceTable">
    <el-table
      ref="table"
      :class="{ show_empty: showEmpty }"
      v-loading="tableLoading"
      :data="tableData"
      @row-click="rowClick"
      highlight-current-row
      :row-class-name="tableRowClassName"
      :header-cell-class-name="headerCellMeshStyle"
      empty-text=" "
      :max-height="tableHeight"
    >
      <el-table-column type="index" :index="indexMethod" :label="$t('publicManagement.No')" align="left" width="88px"></el-table-column>

      <el-table-column
        v-for="(item, index) in chooseInputArr"
        :key="index + 50"
        :label="$t('operationManagement.' + item.prop)"
        :align="item.align || 'left'"
        :min-width="item.width ? item.width : '150'"
      >
        <template slot-scope="scope" v-if="item.prop !== 'No'">
          <span v-if="!choosedThisRow(scope.$index, item.prop, item.editable)">{{ scope.row[item.prop] }}</span>
          <el-input size="mini" v-else class="hours-input" v-model="scope.row[item.prop]" @change="recordProps(scope.row, scope.$index)"></el-input>
        </template>
      </el-table-column>

      <el-table-column :label="$t('ResourceManagement.operation')" fixed="right" align="center" min-width="280">
        <template slot-scope="scope">
          <div class="operations">
            <span size="mini" class="copyRow" @click="copyRow(scope.row)">复制合同信息到空行</span>
            <span @click="deleteRow(scope.$index)" class="deleteRow">删除</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { isEmpty } from '@/utils/rule-verification';
export default {
  components: {},
  props: {
    page: Number,
    pagesize: Number,
    count: Number,
    // 对应必填项的项目数组
    requiredIndex: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 必须唯一的的项目数组
    repeatFiled: {
      type: Array,
      default: () => {
        return [];
      },
    },
    editDiago: {
      type: Boolean,
      default: false,
    },
    applyComp: String,
    tableHeight: {
      type: Number,
      default: 0,
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    oriTableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    chooseInputArr: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      id: undefined,
      showEmpty: false,
      tableData: [],
    };
  },

  watch: {
    oriTableData: {
      immediate: false,
      deep: true,
      handler(newVal) {
        this.tableData = [];
        if (!newVal.length) {
          this.showEmpty = true;

          return;
        }

        this.tableData = JSON.parse(JSON.stringify(newVal));
      },
    },
  },

  methods: {
    handleSizeChange(val) {
      this.$emit('pageChange', 'size', val);
    },
    handleCurrentChange(val) {
      this.$emit('pageChange', 'current', val);
    },
    headerCellMeshStyle(cell) {
      const isCheck = cell.row.find(item => {
        return item.label === '操作';
      });
      if (isCheck) {
        return 'fixed-cell';
      }
    },
    recordProps(item, idx) {
      console.log(item);
      if (item.id) {
        this.$emit('record', item);
      } else {
        this.$emit('record', item, idx);
      }
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      if (this.requiredIndex.includes(rowIndex) || this.repeatFiled.includes(rowIndex)) {
        return 'warning-row';
      }
      return 'normal-row';
    },
    //记录点击了哪一行
    rowClick(row) {
      this.id = row.id;
    },
    //复制合同信息到空行
    copyRow(row) {
      let flag = false;

      for (let blankRow of this.tableData) {
        if (isEmpty(blankRow.projectCode) && !isEmpty(row.projectCode)) {
          blankRow.projectCode = row.projectCode;
          flag = true;
        }
        if (isEmpty(blankRow.projectSeq) && !isEmpty(row.projectSeq)) {
          blankRow.projectSeq = row.projectSeq;
          flag = true;
        }
      }
      this.$emit('update:oriTableData', this.tableData);
      if (isEmpty(row.projectCode) && isEmpty(row.projectSeq)) {
        this.$message.warning('复制内容为空！');
      } else if (flag === true) {
        this.$message.success('复制成功！');
      } else {
        this.$message.warning('没有空行！');
      }
    },
    async deleteRow(index) {
      this.$confirm('<p>此操作将删除该服务项, 是否继续?</p><p>(删除后记得点击保存哦！)</p>', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.tableData.splice(index, 1);
          this.$message({
            type: 'success',
            message: '已删除',
          });
          this.$emit('update:oriTableData', this.tableData);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除！',
          });
        });
    },
    indexMethod(index) {
      return index + 1 < 10 ? '0' + (index + 1) : index + 1;
    },
    /* 把选中的行的某些单元格替换为输入框 */
    choosedThisRow(idx, key, edit) {
      if (this.editDiago) {
        return;
      }

      // 编辑情况下直接编辑
      if (this.tableData[idx].id) {
        return this.id === this.tableData[idx].id && edit;
      }
      // 新建项目情况下
      else {
        return edit && this.tableData[idx];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';

::v-deep .el-table__row {
  .el-table__cell {
    padding: 9.5px 0;
    .hours-input {
      font-size: 14px;
      .el-input__inner {
        color: #515a6e;
        border: none;
        text-align: left;
        height: 29px;
        line-height: 29px;
        background-color: #fff;
      }
    }
  }
}
.editServiceTable {
  @import url('@styles/element-ui.scss');
  padding: 0;
  background: #fff;
  margin: 0 auto;
  margin-top: 0;
  overflow: hidden;
  border-radius: 2px;
  ::v-deep .el-table__fixed-right {
    background-color: #fff;
    font-size: 14px;
    height: 100% !important; //设置高优先，以覆盖内联样式
  }
  ::v-deep .el-table__fixed {
    background-color: #fff;
    font-size: 14px;
    height: 100% !important; //设置高优先，以覆盖内联样式
  }
  ::v-deep .el-table__header {
    th:not(.is-hidden):last-child {
      color: #17233d;
      background: #f8f8f9;
      font-weight: 600 !important;
      .cell {
        padding-bottom: 1px;
      }
    }
    /*     th:not(.is-hidden):last-child::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      color: #DCDEE2;
      background: #DCDEE2;
      left: 0;
      bottom: 6px;
    } */
  }
  .column-pointer {
    cursor: default;
  }
  .operations {
    display: flex;
    justify-content: center;
    .deleteRow {
      display: inline-block;
      cursor: pointer;
      font-size: 14px;
      color: #2d8cf0;
    }
    .copyRow {
      padding-right: 16px;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2d8cf0;
    }
    .editRow::after {
      content: '';
      position: absolute;
      width: 1px;
      background: #fff;
      height: 14px;
      left: 50%;
      top: 36%;
    }
  }
}
::v-deep .el-input__inner {
  color: #515a6e;
  height: 28px;
  line-height: 28px;
}
@include tableClass;
::v-deep .show_empty .el-table__empty-text {
  margin-bottom: 0;
  &::before {
    bottom: -48px;
  }
}
.show_empty {
  height: 610px;
  max-height: 631px !important;
}
</style>
