<template>
  <div>
    <el-drawer id="projFormDrawer" :before-close="handleClose" :with-header="false" :visible="drawerVisable" :size="drawerSize">
      <!--   :title="" -->
      <div class="drawerHeader">
        <p class="title">{{ tableType === 'create' ? $t('projectManagement.createNewProject') : $t('projectManagement.projectHoursTable') }}</p>
        <div class="header-btn-group">
          <el-button @click="handleClose" class="savebtn" size="mini" type="default">{{ $t('projectManagement.cancel') }}</el-button>
          <el-button @click="saveAllServiceItems" class="savebtn" size="mini" type="primary">{{ $t('projectManagement.save') }}</el-button>
        </div>
      </div>
      <div class="app-box" v-if="!authPick">
        <div class="top-box" ref="topBox">
          <el-form label-position="right" :model="ruleForm" ref="ruleForm" :rules="rules" class="filter-form">
            <div class="left-form">
              <div class="colStyle">
                <el-form-item prop="name" :label="$t('projectData.table.projectName')">
                  <el-input v-model="ruleForm.name" :disabled="!(ruleForm.groupId && ruleForm.protocolId)" size="mini"></el-input>
                </el-form-item>
                <el-form-item prop="customerId" :label="$t('projectData.table.client')">
                  <my-select
                    :isLoadMore="false"
                    :isDisabled="tableType === 'edit'"
                    :options="optionGroup.customerOption"
                    v-model="ruleForm.customerId"
                    :placeholder="'请选择客户编号'"
                  ></my-select>
                </el-form-item>
                <el-form-item prop="groupId" :label="$t('projectData.table.department')">
                  <my-select
                    :isLoadMore="false"
                    :isDisabled="tableType === 'edit'"
                    :options="optionGroup.departmentOption"
                    v-model="ruleForm.groupId"
                    @input="spliceProjName"
                    label="department"
                    :placeholder="'请选择部门'"
                  ></my-select>
                </el-form-item>
              </div>
              <div class="colStyle">
                <el-form-item prop="flag" v-if="tableType === 'create'" :label="$t('projectData.table.specialIdentification')">
                  <el-select
                    :disabled="tableType === 'edit'"
                    @change="spliceProjName(ruleForm.flag, ruleForm.flag, 'flag')"
                    label="flag"
                    v-model="ruleForm.flag"
                    placeholder="请选择特殊标识"
                    size="mini"
                  >
                    <el-option v-for="item in optionGroup.specialIdentificationOption" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item prop="start" v-if="tableType === 'edit'" :label="$t('projectData.table.proejectInitiationDate')">
                  <el-date-picker
                    size="mini"
                    v-model="ruleForm.start"
                    class="el-date-picker"
                    type="date"
                    value-format="yyyy-MM-dd"
                    :clearable="false"
                  ></el-date-picker>
                </el-form-item>

                <el-form-item prop="sponsorId" :label="$t('projectData.table.sponsor')">
                  <my-select
                    :isLoadMore="false"
                    :isDisabled="tableType === 'edit'"
                    :options="optionGroup.sponsorOption"
                    v-model="ruleForm.sponsorId"
                    @input="spliceProjName"
                    :placeholder="'请选择申办方'"
                    label="sponsor"
                  ></my-select>
                </el-form-item>
                <el-form-item prop="contactBeginYear" :label="$t('projectData.table.contractStartYear')">
                  <el-date-picker
                    :disabled="tableType === 'edit'"
                    size="mini"
                    type="year"
                    v-model="ruleForm.contactBeginYear"
                    class="el-date-picker"
                    label="contactBeginYear"
                    :value-format="'yyyy'"
                    @change="spliceProjName(ruleForm.contactBeginYear, ruleForm.contactBeginYear, 'contactBeginYear')"
                  ></el-date-picker>
                </el-form-item>
              </div>
              <div class="colStyle">
                <el-form-item prop="start" v-if="tableType === 'create'" :label="$t('projectData.table.proejectInitiationDate')">
                  <el-date-picker
                    size="mini"
                    v-model="ruleForm.start"
                    class="el-date-picker"
                    type="date"
                    value-format="yyyy-MM-dd"
                    :clearable="false"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item prop="end" v-if="tableType === 'edit'" :label="$t('projectData.table.proejectFinishDate')">
                  <el-date-picker
                    size="mini"
                    v-model="ruleForm.end"
                    class="el-date-picker"
                    type="date"
                    value-format="yyyy-MM-dd"
                    :clearable="false"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item prop="protocolId" :label="$t('projectData.table.protocolName')">
                  <my-select
                    :isLoadMore="false"
                    :isDisabled="tableType === 'edit'"
                    :options="tableType === 'edit' ? optionGroup.editProtocolOption : optionGroup.protocolOption"
                    v-model="ruleForm.protocolId"
                    optionLabel="code"
                    @input="spliceProjName"
                    label="protocol"
                    :placeholder="'请选择方案'"
                  ></my-select>
                </el-form-item>
                <el-form-item class="select-width" v-show="tableType === 'edit'" :label="$t('projectData.table.specialIdentification')">
                  <el-select :disabled="true" v-model="ruleForm.flag" placeholder="请选择特殊标识" size="mini">
                    <el-option v-for="item in optionGroup.specialIdentificationOption" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="form-border"></div>
            </div>
            <div class="right-form">
              <el-form-item class="select-width" prop="directorId" :label="$t('projectData.table.director')">
                <my-select :isLoadMore="false" :options="optionGroup.director" v-model="ruleForm.directorId" :placeholder="'请选择总监'"></my-select>
              </el-form-item>
              <el-form-item class="select-width" prop="boId" :label="$t('projectData.table.BO')">
                <my-select :isLoadMore="false" :options="optionGroup.bo" v-model="ruleForm.boId" :placeholder="'请选择BO'"></my-select>
              </el-form-item>
              <el-form-item class="select-width" prop="pmId" :label="$t('projectData.table.PM')" align="left">
                <my-select :isLoadMore="false" :options="optionGroup.pm" v-model="ruleForm.pmId" :placeholder="'请选择PM'"></my-select>
              </el-form-item>
              <el-form-item class="select-width" :label="$t('projectData.table.member')">
                <my-select
                  :isLoadMore="false"
                  :multiple="true"
                  :options="optionGroup.users"
                  v-model="ruleForm.workerIds"
                  :placeholder="'请选择成员'"
                ></my-select>
              </el-form-item>
            </div>
          </el-form>
        </div>

        <div class="bottom-box">
          <div class="header" ref="tableHeader">
            <div class="header-tit">
              {{ tableType === 'create' ? $t('projectManagement.createNewServiceItem') : $t('projectManagement.editServiceItem') }}
            </div>
            <div class="header-btns">
              <el-button @click="addServiceItem" class="createNewbtn" size="medium">{{ $t('projectManagement.createNewServiceItem') }}</el-button>
            </div>
          </div>

          <EditServiceItemTable
            ref="table"
            :tableLoading="tableLoading"
            :tableHeight="proDrawerHeight"
            @record="recordItem"
            @addItem="addItem"
            :oriTableData.sync="oriTableData"
            :chooseInputArr="chooseInputArr"
          />
        </div>
      </div>

      <div v-else class="app-box">
        <Empty v-if="shouldShowErrimg" :class="{ operation_Management_err: isOperationManagementPage }" :image="errImg" :description="errInfo" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import projectDataApi from '@/api/modules/projectData.api.js';
import resourceManagementApi from '@/api/modules/resourceManagement.api';
import projectManagementApi from '@/api/modules/projectManagement.api';

import EditServiceItemTable from './EditServiceItemTable';
import { debounce } from '@/utils/debounce';

export default {
  components: {
    EditServiceItemTable,
  },
  name: 'ProjFormDrawer',
  props: {
    boOption: {
      type: Array,
      default() {
        return [];
      },
    },
    valId: {
      type: Number,
      default: undefined,
    },
    drawerVisable: {
      type: Boolean,
      default: false,
    },
    tableType: {
      type: String,
      default: 'create',
    },
    ruleForm: {
      type: Object,
      default() {
        return {
          page: 1,
          pagesize: 20,
          name: '',
          flag: '',
          boId: '',
          pmId: '',
          workerIds: [],
          directorId: '',
          customerId: '',
          sponsorId: '',
          protocolId: '',
          groupId: '',
          contactBeginYear: '',
          services: [],
          start: '',
          end: '',
        };
      },
    },
  },
  watch: {
    drawerVisable: {
      immediate: false,
      deep: true,
      handler(newVal) {
        if (newVal && this.firstCreate) {
          this.$nextTick(() => {
            this.resizeObserverSize();
            this.getDrawerOptions();
          });
          window.addEventListener('resize', this.resizeObserverSize);
          this.firstCreate = false;
        }
        if (newVal && this.tableType === 'edit' && !this.firstCreate) {
          projectManagementApi.getServiceByProject({ projectIdEq: this.valId }).then(res => {
            // 唯一的抽屉主页面变更table数据的途径
            this.oriTableData = [...res[0].servings];
          });
        }
      },
    },
  },

  activated() {
    this.$nextTick(() => {
      !this.firstCreate ? this.resizeObserverSize() : null;
    });
    window.addEventListener('resize', this.resizeObserverSize);
  },
  deactivated() {
    window.removeEventListener('resize', this.resizeObserverSize);
  },

  created() {
    this.projectName = new Array(4);
  },
  data() {
    // 区分新建和编辑
    const validateData = (rule, value, callback) => {
      // 数组/单值时通用验证
      if (this.tableType === 'create') {
        if (!value || !value.length > 0) {
          callback(new Error('请选择'));
        } else {
          callback();
        }
      }
      // 检查自己的自定义验证规则是否每一条逻辑都能够执行到callback函数！！！
      else {
        callback();
      }
    };
    return {
      proDrawerHeight: 0,
      firstCreate: true,
      drawerSize: 1156,
      showEmpty: false,
      tableLoading: false,
      tableList: [],
      count: 0,
      oriTableData: [],
      otherDomHeight: 0,
      projectArr: [], //项目列表
      choosedContractItem: '',
      allUser: [], //返回的所有供选择的人
      memberSelected: [], //成员
      showMemberSelectedName: [],
      authPick: false,
      projectName: null,

      rules: {
        customerId: [{ validator: validateData, message: '请选择客户编号', trigger: 'change', required: this.tableType === 'create' }],
        groupId: [{ validator: validateData, message: '请选择部门', trigger: 'change', required: this.tableType === 'create' }],
        sponsorId: [{ validator: validateData, message: '请输入申办方名称', trigger: 'change', required: this.tableType === 'create' }],
        start: [{ required: true, message: '请选择开始日期', trigger: 'change' }],
        protocolId: [{ validator: validateData, message: '请输入方案编号', trigger: 'change', required: this.tableType === 'create' }],
        directorId: [{ required: true, message: '请选择', trigger: 'change' }],
        boId: [{ required: true, message: '请选择', trigger: 'change' }],
        pmId: [{ required: true, message: '请选择', trigger: 'change' }],
      },

      chooseInputArr: [
        {
          prop: 'name',
          width: 400,
          icon: 'icon-ziwenjianjia',
          editable: true,
        },
        {
          prop: 'projectCode',
          editable: true,
        },
        {
          prop: 'projectSeq',
          editable: true,
        },
      ],
      errImg: require('@/assets/nodata.png'),
      errInfo: '这里什么都没有发现',
      shouldShowErrimg: false,
      optionGroup: {
        bo: [],
        users: [],
        pm: [],
        director: [],
        departmentOption: [],
        customerOption: [],
        protocolOption: [],
        sponsorOption: [],
        editProtocolOption: [],
        specialIdentificationOption: [
          { name: '普通项目', id: '' },
          { name: 'S', id: 'S' },
          { name: 'C', id: 'C' },
        ],
      },
    };
  },
  methods: {
    addItem() {},
    recordItem(item, idx) {
      if (!idx) {
        const editItemId = this.oriTableData.findIndex(val => {
          return val.id === item.id;
        });
        this.oriTableData[editItemId] = Object.assign({}, item);
      } else {
        this.oriTableData[idx] = Object.assign({}, item);
      }
    },
    resizeObserverSize() {
      const resizeDom = debounce(() => {
        const topBoxHeight = this.$refs.topBox?.offsetHeight ? this.$refs.topBox.offsetHeight : 0;
        const tableHeaderHeight = this.$refs.tableHeader?.offsetHeight ? this.$refs.tableHeader.offsetHeight : 0;
        this.otherDomHeight = topBoxHeight + tableHeaderHeight + 30;

        this.proDrawerHeight = window.innerHeight - this.otherDomHeight - 64;
      }, 100);
      resizeDom();
    },
    async spliceProjName(val, chooseObj, label) {
      if (label === 'sponsor') {
        this.ruleForm.protocolId = '';
        let [protocolData] = await resourceManagementApi.protocolsData({ page: 1, pagesize: 999999, sponsorIdEq: this.ruleForm.sponsorId });
        this.optionGroup.protocolOption = [...protocolData.protocols];
      }

      switch (label) {
        case 'department':
          if (!chooseObj) {
            this.projectName[1] = '';
          } else {
            this.projectName[1] = chooseObj.name;
          }
          break;
        case 'flag':
          if (!chooseObj) {
            this.projectName[3] = '';
          } else {
            this.projectName[3] = chooseObj;
          }

          break;
        case 'contactBeginYear':
          if (!chooseObj) {
            this.projectName[2] = '';
          } else {
            this.projectName[2] = chooseObj;
          }

          break;
        case 'protocol':
          if (!chooseObj) {
            this.projectName[0] = '';
          } else {
            this.projectName[0] = chooseObj.code + ' ';
          }
          break;
      }
      this.ruleForm.name = this.projectName.join('');
    },
    handleClose() {
      this.$confirm('确认关闭？').then(() => {
        this.afterCloseClear();
      });
    },
    afterCloseClear() {
      // 关闭清空
      this.$refs.ruleForm.resetFields();
      this.oriTableData = [];
      this.optionGroup.protocolOption = [...this.optionGroup.editProtocolOption];
      this.$emit('update:drawerVisable', false);
    },
    async getDrawerOptions() {
      let [data] = await projectDataApi.getDepartment({ page: 0, pagesize: 0 });
      this.optionGroup.departmentOption = [...data.groups];
      let [customerData] = await resourceManagementApi.customersData({ page: 1, pagesize: 999999 });
      this.optionGroup.customerOption = [...customerData.customers];

      let [sponsorData] = await resourceManagementApi.sponsorData({ page: 1, pagesize: 999999 });
      this.optionGroup.sponsorOption = [...sponsorData.sponsors];

      let [protocolData] = await resourceManagementApi.protocolsData({ page: 1, pagesize: 999999 });
      this.optionGroup.editProtocolOption = [...protocolData.protocols];
      this.optionGroup.protocolOption = [...protocolData.protocols];
      let [leaderData] = await projectDataApi.getDesignativeUserName({ page: 1, pagesize: 99999 });
      this.optionGroup.users = this.boOption;
      this.optionGroup.pm = [...leaderData.leaders];
      this.optionGroup.bo = [...leaderData.leaders];
      this.optionGroup.director = [...leaderData.leaders];
    },
    //添加服务项
    addServiceItem() {
      this.oriTableData.unshift({ name: '', projectCode: '', projectSeq: '' });
    },

    //保存服务项
    async saveAllServiceItems() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          let s = {
            ...this.ruleForm,
            services: [],
          };
          s.contactBeginYear = Number(s.contactBeginYear);
          s.directorId = s['directorId'].toString();
          s.pmId = s['pmId'].toString();
          s.boId = s['boId'].toString();
          s.workerIds.forEach((item, index) => {
            s.workerIds[index] = item.toString();
          });
          for (let obj of this.oriTableData) {
            s.services.push({ name: obj.name, projectSeq: obj.projectSeq, projectCode: obj.projectCode });
          }

          let promiseSend = this.tableType === 'create' ? projectDataApi.addProject(s) : projectDataApi.editProject(s, this.valId);
          promiseSend.then(res => {
            if (Number(res[2].status) === 200) {
              this.$message.success('保存成功！');

              this.$nextTick(() => {
                this.afterCloseClear();
                this.$refs.ruleForm.clearValidate();
                this.$listeners.toRefresh();
              });
            } else if (Number(res[1].code) === 500 && res[1].metadata.name === 'not_uniq') {
              this.$message.error('项目名称不唯一，请重新输入');
            } else {
              return;
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#projFormDrawer {
  .drawerHeader {
    display: flex;
    font-size: 16px;

    font-weight: 500;
    color: #17233d;
    padding: 0 32px;
    align-items: center;
    margin: 0;
    .header-btn-group {
      margin-left: auto;
      .el-button {
        height: 32px;
        background: #fff;
        border-radius: 4px;
        border: 1px solid #dcdee2;
      }
    }
  }
  .app-box {
    padding: 0;
    flex-grow: 1;
    overflow-y: scroll;
    ::v-deep .el-input__inner {
      width: 230px;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 14px;
      height: 51px;

      .header-btns {
        display: flex;
        align-items: center;
        height: 36px;
        font-size: 14px;
        .savebtn {
          margin-left: 16px;
        }
      }
    }

    .top-box {
      width: 100%;
      font-size: 14px;
      background-color: #fff;
      border-radius: 2px;
      font-size: 16px;

      font-weight: 500;
      color: #17233d;
      border-top: 1px solid #e9e9e9;
      .filter-form {
        display: flex;
        justify-content: flex-start;
        .left-form {
          display: flex;
          justify-content: flex-start;
          // max-width: 1300px;
          width: 708px;
          font-size: 14px;
          position: relative;
          .el-form-item {
            margin-bottom: 0;
            padding-bottom: 14px;
            width: 200px;
            ::v-deep .el-form-item__label {
              display: block;
              text-align: left;
              line-height: 22px;
              height: 22px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #17233d;
              padding-bottom: 4px;
            }
            ::v-deep .el-input__inner {
              color: #515a6e;
              height: 32px;
              width: 200px;
            }
            .datepick {
              ::v-deep .el-date-editor--daterange .el-input__inner {
                color: #515a6e;
                width: 230px;
              }

              ::v-deep .el-range__close-icon {
                display: none;
              }
            }
            ::v-deep .is-error .is-required {
              padding: 0;
            }
            ::v-deep .el-form-item__error {
              padding: 0;
            }
          }
          .form-border {
            position: absolute;
            right: 0;
            height: 100%;
            top: 12%;
          }
          .form-border:after {
            content: '';
            position: absolute;
            right: 0;
            height: 250px;
            width: 1px;
            background: #fff;
            opacity: 0.5;
          }
        }
        .right-form {
          display: flex;
          flex-direction: column;
          margin-top: 24px;
          margin-left: auto;
          margin-right: 26px;
          .el-form-item {
            margin-bottom: 0;
            padding-bottom: 14px;
            ::v-deep .el-form-item__label {
              display: inline-block;
              width: 54px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #17233d;
              padding-bottom: 4px;
            }
            ::v-deep .el-input__inner {
              color: #515a6e;
              width: 200px !important;
              height: 32px;
            }
            ::v-deep .el-form-item__content {
              display: inline-block;
            }
            ::v-deep .is-error .is-required {
              padding: 0;
            }
            ::v-deep .el-form-item__error {
              padding: 0;
            }
          }
        }
      }

      .tit {
        margin-bottom: 8px;
        i {
          margin-right: 3px;
          color: red;
        }
      }

      .colStyle {
        // width: 224px;
        padding-top: 24px;
        padding-left: 32px;
        padding-right: 24px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        .projectname {
          .el-input {
            width: 100%;
            border-radius: 4px;
            height: 40px;
          }
        }
        .member {
          margin-top: 30px;
          .el-select {
            width: 100%;
            border-radius: 4px;
            height: 40px;
          }
          .multiple {
            margin: 4px 0 0 0;
            display: flex;
            flex-wrap: wrap;
            .el-tag {
              margin: 0px 4px 4px 0;
            }
          }
        }
      }
    }

    .bottom-box {
      background-color: #fff;
      padding: 0 32px;
      border-radius: 2px;
    }

    ::v-deep .empty {
      height: 80%;

      .empty__image {
        width: 286px;
        height: 214px;
      }
      &.operation_Management_err {
        .empty__image {
          width: 267px;
          height: 225px;
        }
      }
    }
  }
}
</style>
