<template>
  <div id="project-list">
    <filter-pane :statementType="'projectData.projectDataSummary'" ref="filterPane">
      <template v-slot:filter>
        <el-form :model="dataForm" class="ruleForm" ref="dataForm">
          <div class="col1">
            <el-form-item class="select-width" prop="boIdIn">
              <my-select :options="optionGroup.boOption" v-model="dataForm.boIdIn" :placeholder="'请选择BO'"></my-select>
            </el-form-item>
            <el-form-item class="select-width" prop="pmIdIn">
              <my-select :options="optionGroup.boOption" v-model="dataForm.pmIdIn" :placeholder="'请选择PM'"></my-select>
            </el-form-item>
            <el-form-item class="general-input" prop="keyword">
              <el-input placeholder="键入员工姓名/项目名称/申办方/服务项" size="mini" v-model="dataForm.keyword" clearable> </el-input>
            </el-form-item>
            <el-form-item v-if="exportPermission" prop="addProjectBtn" class="add-project-btn">
              <el-button type="primary" size="small" @click="loadDrawer('add')">{{ '添加项目' }}</el-button>
            </el-form-item>
          </div>

          <div class="col2">
            <el-form-item label="项目开始时间：" label-width="110px">
              <date-pick ref="dateStart" @onChange="changeDate" align="left" :dateValue="projectBeginDate" :dateCompName="'projectBeginDate'" />
            </el-form-item>
            <el-form-item class="button-group">
              <el-button class="buttonReset" size="mini" @click="resetForm">
                <span class="Reset">{{ $t('projectData.filter.reset') }}</span>
              </el-button>
              <el-button class="buttonSearch" type="primary" size="mini" @click="submitForm">
                <span class="ButtonDspr">{{ $t('projectData.filter.search') }}</span>
              </el-button>
            </el-form-item>
          </div>
        </el-form>
      </template>
    </filter-pane>
    <div class="listTable">
      <el-table
        :tableLoading="tableLoading"
        :data="tableList"
        empty-text=" "
        :class="{ show_empty: showEmpty && !tableLoading }"
        class="summaryTable"
        :max-height="tableHeight - 40"
      >
        <el-table-column
          v-for="(item, index) in tableColumns"
          :key="index + 50"
          :prop="item.prop"
          :label="item.label"
          :align="item.align"
          :min-width="setColumnWidth(item.label)"
        >
          <template slot-scope="scope">
            <span v-if="item.prop === 'name'" slot="reference" class="column-pointer" @click="loadDrawer('edit', scope.row)"
              >{{ scope.row[item.prop] }}
            </span>
            <span v-else>{{ scope.row[item.prop] }} </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="dataForm.page"
      :page-sizes="[10, 20, 30, 50, 100]"
      :page-size="dataForm.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
    >
    </el-pagination>

    <ProjFormDrawer
      @toRefresh="searchForm"
      :tableType="tableType"
      :drawerVisable.sync="drawerVisable"
      :boOption="optionGroup.boOption"
      :ruleForm="ruleForm"
      :valId="valId"
    />
  </div>
</template>

<script>
import { mixinsProject } from '@/mixins/projectData';
import FilterPane from '@/views/project-data/components/FilterPane';
import ProjFormDrawer from './components/ProjFormDrawer';
import projectDataApi from '@/api/modules/projectData.api.js';
import projectManagementApi from '@/api/modules/projectManagement.api';
export default {
  mixins: [mixinsProject],
  components: {
    FilterPane,
    ProjFormDrawer,
  },
  name: 'ProjectList',
  data() {
    return {
      valId: undefined,
      tableType: 'create',
      optionGroup: { boOption: [] },
      showEmpty: false,
      tableLoading: false,
      tableList: [],
      count: 0,
      dataForm: {
        page: 1,
        pagesize: 20,
        deptIdIn: '',
        directorIdIn: '',
        boIdIn: '',
        pmIdIn: '',
        projectBeginDateGt: '',
        projectBeginDateLt: '',
        keyword: '',
      },
      serviceTableData: [],
      ruleForm: {},
      chooseInputArr: [
        {
          prop: 'serviceItem',
          minWidth: 400,
          icon: 'icon-ziwenjianjia',
          editable: true,
        },
        {
          prop: 'contractSerialNo',
          editable: true,
        },
        {
          prop: 'contractNo',
          editable: true,
        },
      ],

      drawerVisable: false,
    };
  },
  computed: {
    // 表格的表头元素
    tableColumns() {
      return [
        { prop: 'name', label: this.$t('projectData.table.projectName') },
        { prop: 'clientCode', label: this.$t('projectData.table.clientCode') },
        {
          prop: 'sponsorName',
          label: this.$t('projectData.table.sponsor'),
        },
        { prop: 'protocolName', label: this.$t('projectData.table.protocolName') },
        { prop: 'boName', label: this.$t('projectData.table.BO') },
        { prop: 'pmName', label: this.$t('projectData.table.PM') },
        { prop: 'start', label: this.$t('projectData.table.start') },
        { prop: 'end', label: this.$t('projectData.table.end') },
      ];
    },
    exportPermission() {
      return this.$store.state.app.userConfig.permissionsKeys?.ProjectListCreate;
    },
  },
  created() {
    this.searchForm();
  },
  methods: {
    // 控制新建/编辑项目的弹窗
    async loadDrawer(type, row) {
      if (type === 'add') {
        this.ruleForm = {
          page: 1,
          pagesize: 20,
          name: undefined,
          flag: undefined,
          boId: undefined,
          pmId: undefined,
          workerIds: [],
          directorId: undefined,
          customerId: undefined,
          sponsorId: undefined,
          protocolId: undefined,
          groupId: undefined,
          contactBeginYear: undefined,
          services: [],
          start: '',
          end: '',
        };
        this.tableType = 'create';
        this.drawerVisable = true;
      } else {
        this.tableType = 'edit';
        let [formData] = await projectManagementApi.getProjectDetail({ id: row.id });
        this.valId = row.id;
        formData.directorId = formData.director[0]?.id ? String(formData.director[0].id) : undefined;
        formData.boId = formData.bo[0]?.id ? String(formData.bo[0].id) : undefined;
        formData.pmId = formData.pm[0]?.id ? String(formData.pm[0].id) : undefined;
        formData.groupId = formData.groupId ? String(formData.groupId) : undefined;
        formData.contactBeginYear = formData.contactBeginYear ? String(formData.contactBeginYear) : '';
        formData.sponsorId = formData.sponsorId ? String(formData.sponsorId) : undefined;
        formData.protocolId = formData.protocolId ? String(formData.protocolId) : undefined;
        formData.customerId = formData.customerId ? String(formData.customerId) : undefined;
        let workerIdArr = [];
        formData.worker.forEach(item => {
          workerIdArr.push(item.id);
        });
        formData.workerIds = [...workerIdArr];
        formData.services = [];

        this.ruleForm = { ...formData };
        /*         let [serviceData] = await projectManagementApi.getServiceByProject({ project: row.id });

        this.serviceTableData = [...serviceData.servings]; */
        this.drawerVisable = true;
      }
    },

    async getOptions() {
      let [data] = await projectDataApi.getSearchUserName({ page: 1, pagesize: 99999 });
      this.optionGroup.boOption = [...data.user];
    },
    async searchForm() {
      this.tableLoading = true;
      let submitForm = this.dataForm;
      submitForm.logDateLt = '';
      submitForm.logDateGt = '';
      let [data] = await projectDataApi.projectList(submitForm);
      this.tableLoading = false;
      if (!data.projects || !data.projects.length) {
        this.tableList = [];
        this.showEmpty = true;
        return;
      }
      this.showEmpty = false;
      this.tableList = data.projects;
      this.count = data.count;
    },
    setColumnWidth(label) {
      switch (label) {
        case 'PM':
        case '申办方':
          return '140px';
        case 'BO':
        case '客户编号':
          return '100px';
        case '开始时间':
        case '结束时间':
          return '120px';

        case '项目名称':
          return '280px';
        case '方案':
          return '190px';
      }
    },
    submitForm() {
      this.dataForm.page = 1;
      this.dataForm.pagesize = 20;
      this.count = 0;
      this.searchForm();
    },
    handleSizeChange(val) {
      this.dataForm.pagesize = val;
      this.searchForm();
    },
    handleCurrentChange(val) {
      this.dataForm.page = val;
      this.searchForm();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
@include tableClass;
#project-list {
  padding: 0 24px;
  background: #f8f8f9;
  .ruleForm {
    @include commonElForm;
    .col1 {
      @include commonFilterRow;
    }
    .col2 {
      @include commonFilterRow;
    }
  }
  .add-project-btn {
    margin-left: auto !important;
    margin-right: 9px !important;
    .el-button {
      font-size: 14px;
    }
  }
  .listTable {
    background-color: #fff;
    margin-top: 16px;
    padding: 16px 32px;
    padding-bottom: 0;
    .summaryTable {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      // height: 739px;
      margin-bottom: 0;
      .column-pointer {
        cursor: pointer;
        color: #2d8cf0;
      }
    }
  }
  .pagination {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .show_empty {
    height: 610px;
    max-height: 631px !important;
  }
  @import url('@styles/element-ui.scss');
}
</style>
