import FilterPane from '@/views/project-data/components/FilterPane';
import TablePane from '@/views/project-data/components/TablePane';
import { dayFormat } from '@/utils/time-tools';
import projectDataApi from '@/api/modules/projectData.api.js';
import responseCheck from '@/utils/saveAs';
import { debounce } from '@/utils/debounce';
export const mixinsProject = {
  components: {
    FilterPane,
    TablePane,
  },
  data() {
    return {
      tableLoading: false,
      dataForm: {
        deptId: undefined,
        directorId: undefined,
        boId: undefined,
        pmId: undefined,
        realBoId: undefined,
        realPmId: undefined,
        key: undefined,
        projectBeginDateGt: undefined,
        projectBeginDateLt: undefined,
        logDateGt: undefined,
        logDateLt: undefined,
        projectName: undefined,
      },
      submitTip: false,
      projectBeginDate: [],
      logDate: [],
      optionGroup: {
        departmentOption: [],
        directorOption: [],
        boOption: [],
      },
      exportMore: '导出更多',
      exportContent: '导出报表',
      exportLoading: false,
      tableData: [],
      otherDomHeight: 0,
      tableHeight: 0,
      authPick: false,
    };
  },
  mounted() {
    this.getOptions();
    this.resetLogDate();
    this.$nextTick(() => {
      this.resizeObserverSize();
    });
    window.addEventListener('resize', this.resizeObserverSize);
  },
  activated() {
    this.$nextTick(() => {
      this.resizeObserverSize();
    });
    window.addEventListener('resize', this.resizeObserverSize);
  },
  deactivated() {
    window.removeEventListener('resize', this.resizeObserverSize);
  },
  methods: {
    resizeObserverSize() {
      const resizeDom = debounce(() => {
        const tabPaneHeight = this.tabPaneHeight ? this.tabPaneHeight : 0;
        this.otherDomHeight = this.$refs.filterPane.$el.offsetHeight + tabPaneHeight + 118;
        this.tableHeight = window.innerHeight - this.otherDomHeight - 30;
      }, 100);
      resizeDom();
    },
    async getOptions() {
      let [data] = await projectDataApi.getSearchUserName({ page: 1, pagesize: 99999 });
      this.optionGroup.boOption = [...data.user];
    },
    resetLogDate() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      this.dataForm.logDateGt = dayFormat(start, 'YYYY-MM-DD');
      this.dataForm.logDateLt = dayFormat(end, 'YYYY-MM-DD');
      this.logDate = [start, end];
    },
    changeDate(list, name) {
      if (name === 'projectBeginDate') {
        this.dataForm.logDateGt = '';
        this.dataForm.logDateLt = '';
        this.logDate = [];
      }
      // 针对清空的操作
      if (!list) {
        this.dataForm.projectBeginDateGt = '';
        this.dataForm.projectBeginDateLt = '';
        this.projectBeginDate = [];
        return;
      }
      this.dataForm[name + 'Lt'] = list[1];
      this.dataForm[name + 'Gt'] = list[0];

      this[name] = [...list];
    },
    async exportData() {
      try {
        this.exportContent = '导出中...';
        this.exportMore = '导出中...';
        this.exportLoading = true;
        const res = await projectDataApi.projExport(this.dataForm, this.exportName);

        responseCheck(res[2]);

        this.exportContent = '导出报表';
        this.exportMore = '导出更多';
        this.exportLoading = false;
      } catch (err) {
        this.exportLoading = false;
        this.$message.error('导出项目失败');
      }
    },
    resetForm() {
      this.refreshCount ? (this.refreshCount = 5) : null;
      this.projectIds ? (this.projectIds = []) : null;
      this.$refs['dataForm'].resetFields();

      if (this.$refs.dateStart) {
        this.dataForm.projectBeginDateGt = '';
        this.dataForm.projectBeginDateLt = '';
        this.projectBeginDate = [];
      }
      if (this.$refs.dateCount) {
        this.resetLogDate();
      }
    },
  },
};
